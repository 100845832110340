<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()

const prices = computed(() => store.getters['system/getTotalPrice'])
const products = computed(() => store.getters['system/products'])
const images = computed(() => store.getters['system/previewImages'])
const montageCost = computed(() => store.getters['system/montageCost'])
// const summary = computed(() => store.getters['builder/summary'])
// const system = computed(() => store.getters['builder/builder'])

function printed () {}
function addToCart () {}
function onChange (e) {}
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <h3>Zusammenfassung - Alles auf einen Blick</h3>
      <div class="table-responsive">
        <table class="table">
          <tbody>
          <tr v-for="(product, index) in products" :key="index">
            <td style="width: 250px">
              <div class="text-center">
                <div class="product-main-image" v-for="(items, index) in images" :key="index">
                  <template v-if="product.config === items.config">
                    <template v-for="(image, i) in items.images" :key="i">
                      <img :src="$filters.image(image.path, '200x')"  />
                    </template>
                  </template>
                </div>
              </div>
            </td>
            <td>
              <p>
                <strong>{{ product.name }}</strong>
              </p>
              <ul>
                <li v-for="(step, index) in product.steps" :key="index">
                  {{ step.name }}: <span class="link">{{ step.selectedLabel }}&nbsp;</span>
                </li>
              </ul>
            </td>
            <td class="text-right">
              <h5><span class="discount-new"><strong>{{ $filters.currency(product.price.total) }}</strong></span></h5>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td><strong>Gesamtpreis:</strong></td>
            <td>
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="montage" @click="onChange">
                <label class="form-check-label" for="montage">
                  Mit Komplettmontage zzg. <span class="text-danger">{{ $filters.currency(montageCost) }}</span>
                </label>
              </div>
            </td>
            <td class="text-right" style="width: 300px">
              <h5 v-if="montageStatus">Montage: <span class="text-danger">{{ $filters.currency(montageCost) }}</span></h5>
              <h5>Gesamt:
                <span class="text-danger" v-if="prices && prices.discounted > 0">{{ $filters.currency(prices.discounted + ((montageStatus) ? montageCost : 0)) }}</span>
                <span class="text-price" :class="{'old-price': prices.discounted > 0}">{{ $filters.currency(prices.price + ((montageStatus) ? montageCost : 0)) }}</span>
              </h5>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-right">
              <span class="small-text text-muted">ink. 19% MwSt. & Versandkosten</span>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <button class="btn btn-dark" @click="printed()">Drucken</button>
            </td>
            <td colspan="2" class="text-right">
              <button class="btn btn-primary" @click="addToCart">In den Warenkorb</button>
              <button class="btn btn-outline-primary" @click="showRequestForm">Unverbindliche Anfrage</button>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>
.product-main-image img {
  display: block;
  position: absolute;
}
</style>
